import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Loader from "react-loader-spinner";
import LoaderHoc from "../../../components/HOC/LoaderHoc";
import { Alert } from "@material-ui/lab";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";


import {
  FormControlLabel,
  Button,
  Checkbox,
  TextField
} from "@material-ui/core";
import Radio from "@material-ui/core/Radio/Radio";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import download from "assets/img/download.png";
import { LOADER_TYPE, SECONDARY_COLOR } from "global_constants/app_constants";
import GridItem from "components/Grid/GridItem";
import ImportDesignModal from "../../../components/ImportDesignModal";
import { Base64 } from "js-base64";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import theme from "theme";
import FormControl from "@material-ui/core/FormControl";
import { SDCloudBackendAPIWithToken } from "../../../apis/backendAPI";
const useStyles = makeStyles(styles);
// import { browserHistory } from 'react-router-dom';


const DesignModal = ({
  visible,
  onClose,
  onCreateTemplate,
}) => {
  const classes = useStyles();
  const [graphXML, setGraphXML] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [templateNameYaml, setTemplateYamlName] = useState("");
  const [topology_layout, setTopologyLayout] = useState("Default Layout");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [inventory_file, setFile] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // const menuItems = ['Default Layout', 'Criterion Layout']

  const menuItems = ["Default Layout", "Criterion Layout"]


  const handleNameChange = e => {
    const { name, value } = e.target;
    setTemplateName(value)
  };

  const handleYamlNameChange = e => {
    const { name, value } = e.target;
    setTemplateYamlName(value)
  };

  const handleXMLChange = e => {
    const { xmaldata, value } = e.target;
    setGraphXML(value)
  };

  const handleOnFileChange = e => {
    const target = e.target;
    setFile(target.files[0]);
    console.log(target, target.files[0], 'target file instance')
  }
  
  const handle_layout_selection = e => {
    const target = e.target;
    console.log(target, 'target data')
    setTopologyLayout(target.value)
  }

  const onSubmit = () => {
    var re = /^[a-zA-Z0-9-_]+$/;
    if (templateName && re.test(templateName) && templateName.length > 3) {
      setBtnDisabled(true);
      onCreateTemplate(templateName,null, false, false, );

      localStorage.setItem("graphXML", Base64.encode(graphXML))
    } else{
      if(re.test(templateName) && templateName.length <= 3){
        setNotificationMessage('Please enter a valid template name with atleast 4 characters. Special characters are not allowed except for - and _');
      }
      setShowNotification(true);
      setNotificationType("error");
      if(JSON.parse(localStorage.getItem("profileData")).privilege === "admin"){
        setNotificationMessage('Admin can not create a template');
      }else{
      setNotificationMessage('Please enter a valid template name with atleast 4 characters. Special characters are not allowed except for - and _');
      }
    }
  };

  const onSubmitYamlInventory = () => {
    var re = /^[a-zA-Z0-9-_]+$/;
    if (templateNameYaml && re.test(templateNameYaml) && templateNameYaml.length > 3) {
      setBtnDisabled(true);
      setIsLoading(true);
      const formData = new FormData();

      formData.append('file', inventory_file);
      formData.append('template_name', templateNameYaml);
      formData.append('layout_type', topology_layout);

      SDCloudBackendAPIWithToken()
      .post(
        `/designer/yaml_to_template`, formData
      )
      .then((resp) => resp.data)
      .then((resp) => {
        console.log(resp, 'data from backend')
        setIsLoading(false);
        
        // debugger;
        localStorage.setItem("graphXML", Base64.encode(resp['xml']))

        onCreateTemplate(templateNameYaml,null, false, false, );
        // browserHistory.push('/login');

      })
      .catch((err) => {
        let message = err.response.data.message;
        console.log(message);
        // setIsLoading(false);
      });
    // setrow(rowstemp.data);
      // setIsLoading(false);

      // onCreateTemplate(templateNameYaml,null, false, false, );

      // localStorage.setItem("graphXML", Base64.encode(graphXML))
    } else{
      if(re.test(templateNameYaml) && templateNameYaml.length <= 3){
        setNotificationMessage('Please enter a valid template name with atleast 4 characters. Special characters are not allowed except for - and _');
      }
      setShowNotification(true);
      setNotificationType("error");
      if(JSON.parse(localStorage.getItem("profileData")).privilege === "admin"){
        setNotificationMessage('Admin can not create a template');
      }else{
      setNotificationMessage('Please enter a valid template name with atleast 4 characters. Special characters are not allowed except for - and _');
      }
    }
  };

  return (
    <ImportDesignModal
      title="Enter Graph XML"
      visible={visible}
      onClose={onClose}
      style
    >
      {/* <GridItem className="content" xs={24} sm={24} md={24} lg={24} style={{width:"900px;"}}> */}
      <GridItem className="content" xs={5}>
      {/* <TextField
          style={{ paddingRight: "0px", margin: 0 , width:"800px"}}
          rows={20}
          multiline
          label="Graph XML"
          value={details.graphXML}
          onChange={handleChange}
          id="xml"
          name="xml"
          variant="filled"
      /> */}
        <TextField
          type="text"
          variant="filled"
          name="templateName"
          fullWidth
          label="Template Name"
          value={templateName}
          onChange={handleNameChange}
        />
        <FormControl
              fullWidth
          >
          <TextField
              style={{ paddingRight: "0px", margin: 0 }}
              rows={10}
              multiline
              label="Graph XML"
              InputLabelProps={{ disableAnimation: true, shrink: false }}
              InputProps={{ disableUnderline: true }}
              id="graphxml"
              name="graphxml"
              value={graphXML}
              onChange={handleXMLChange}
              variant="filled"
          />
          </FormControl>
          
        {!btnDisabled ? (
            <>
           <Button
            variant="contained"
            color="secondary"
            disableElevation
            size="large"
            fullWidth
            onClick={onSubmit}
            disableFocusRipple
          >
            Import XML
          </Button>
          </>
        ) : null}
        {showNotification ? (
            <Alert
                onClose={() => setShowNotification(false)}
                severity={notificationType}
            >
              {notificationMessage}
            </Alert>
        ) : null}
      </GridItem>
      <GridItem className="content" xs={2}>
        <p style={{ textAlign: 'center'}}>or</p>
      </GridItem>
      <GridItem className="content" xs={5}>
      {/* <TextField
          style={{ paddingRight: "0px", margin: 0 , width:"800px"}}
          rows={20}
          multiline
          label="Graph XML"
          value={details.graphXML}
          onChange={handleChange}
          id="xml"
          name="xml"
          variant="filled"
      /> */}
        <TextField
          type="text"
          variant="filled"
          name="templateNameYaml"
          fullWidth
          label="Template Name"
          value={templateNameYaml}
          onChange={handleYamlNameChange}
        />
        {/* <label>
          Pick a fruit:
          <select>
            <option value="someOption">Some option</option>
            <option value="otherOption">Other option</option>
          </select>
        </label> */}
        {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          fullWidth
          name="Layout"
          label="Select Layout"
          value={topology_layout}
          onChange={handle_layout_selection}
          native
          error
          helperText={"select topology"}
          className={classes.purposeDropdown}
          style={{marginTop: "3%"}}
        >
          {menuItems.map(function(object, i){
              // return <MenuItem value={object}>{object}</MenuItem>;
              return <option value={object}>{object}</option>
          })}
         
        </Select>
        
        <FormControl
              fullWidth
          >
          {/* <TextField
              style={{ paddingRight: "0px", margin: 0 }}
              rows={10}
              multiline
              label="Graph XML"
              InputLabelProps={{ disableAnimation: true, shrink: false }}
              InputProps={{ disableUnderline: true }}
              id="graphxml"
              name="graphxml"
              value={graphXML}
              onChange={handleXMLChange}
              variant="filled"
          /> */}
            <div class="design-create-card">
              <p
                class={`create-card-title ${classes.font}`}
                style={{ color: "lightgray" }}
              >
                Upload Inventory Yaml
              </p>
              {/* <button
                className={`button download-design-button ${classes.font}`}
                style={{ cursor: "pointer" }}
                // onClick={() => setIsImportModal(true)}
              >
                {" "}
                <img src={download} className="" /> <br /> Import{" "}
              </button> */}
              {/* <Button
                // variant="contained"
                // component="label"
                variant="contained"
                color="secondary"
              > */}
                {/* Upload File */}
                <input
                  id="inventory_yaml"
                  type="file"
                  name="inventory_file"
                  onChange={handleOnFileChange}
                />
              {/* </Button> */}

            </div>
          </FormControl>
          
        {!btnDisabled ? (
            <>
           <Button
            variant="contained"
            color="secondary"
            disableElevation
            size="large"
            fullWidth
            onClick={onSubmitYamlInventory}
            disableFocusRipple
          >
            Import Netbox Yaml
          </Button>

          </>
        ) : null}
        {isLoading ? ( // Conditional rendering based on the loading state
                <div style={{ alignItems:'center', width: '100%' }}>
                    <LoaderHoc isLoading={isLoading}></LoaderHoc> 
                </div>
              ) : null}

        {showNotification ? (
            <Alert
                onClose={() => setShowNotification(false)}
                severity={notificationType}
            >
              {notificationMessage}
            </Alert>
        ) : null}
      </GridItem>
    </ImportDesignModal>
  );
};

export default withRouter(DesignModal);